import React, { useState, useEffect } from 'react';
import Authimg from '../assets/images/login-bg.svg';

import { Link, useParams, useNavigate } from "react-router-dom";

import { useFormik } from 'formik';
import { changResPaaSchema } from '../schemas';

import { FaLock, FaCaretUp } from "react-icons/fa";

import Loader from '../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { postResetPassword } from '../apiservices/ApiServices';
import Logo from "../assets/images/logo-inverse.svg";

const initialValues = {
    password: '',
    confirmpassword: ''
}

const ChangePassword = () => {
    const [isLoading, setLoading] = useState(false);
    const [isFalse, setFalse] = useState(false);

    const navigate = useNavigate();
    const { id, token } = useParams();

    const [lenghts, setLenghts] = useState('Fail');
    const [lowerCase, setLowerCase] = useState('Fail');
    const [upperCase, setUpperCase] = useState('Fail');
    const [numbers, setNumbers] = useState('Fail');
    const [specialChr, setspecialChr] = useState('Fail');
    const [regexallPass, setregexallPass] = useState('Fail');

    const [clenghts, setCLenghts] = useState('Fail');
    const [clowerCase, setCLowerCase] = useState('Fail');
    const [cupperCase, setCUpperCase] = useState('Fail');
    const [cnumbers, setCNumbers] = useState('Fail');
    const [cspecialChr, setCspecialChr] = useState('Fail');
    const [cregexallPass, setCregexallPass] = useState('Fail');


    useEffect(() => {

    }, [id, token])

    const { values, errors, touched, handleBlur, handleChange, setFieldTouched, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: changResPaaSchema,
        onSubmit: (vlaues, action) => {
            const isVarify = localStorage.getItem('isVarify');
            setLoading(true);
            if (isVarify === "true") {
                let postdata = {
                    "password": vlaues.password,
                    "confirmPassword": vlaues.confirmpassword,
                    "email": id,
                    "token": token,
                }

                postResetPassword(postdata).then((res) => {
                    try {
                        console.log('res', res.data)
                        if (res.data === false) {
                            setLoading(false);
                            toast.error('Request false');
                            setFalse(true);
                        }
                        else {
                            setFalse(false);
                            setLoading(false);
                            toast.success('set new Password Successful');
                            action.resetForm();
                            setTimeout(() => navigate("/"), 3000);
                        }
                    } catch (error) {
                        setLoading(false);
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                });
            } else {
                setLoading(false);
                toast.error('RECAPTCHA verification failed');
                setTimeout(() => window.location.reload(true), 3000);
            }

        }
    })

    const handleChangePass = async (e) => {
        let values = e.target.value;

        const lenghts = new RegExp("^(?=.{8,})");
        const lowerCase = new RegExp("^(?=.*[a-z])");
        const upperCase = new RegExp("^(?=.*[A-Z])");
        const numbers = new RegExp("^(?=.*[0-9])");
        const specialChr = new RegExp("^(?=.*[!@#$%^&*])");
        const regexallPass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        if (lenghts.test(values)) {
            setLenghts('Pass')
        } else {
            setLenghts('Fail')
        }

        if (lowerCase.test(values)) {
            setLowerCase('Pass')
        } else {
            setLowerCase('Fail')
        }

        if (upperCase.test(values)) {
            setUpperCase('Pass')
        } else {
            setUpperCase('Fail')
        }

        if (numbers.test(values)) {
            setNumbers('Pass')
        } else {
            setNumbers('Fail')
        }

        if (specialChr.test(values)) {
            setspecialChr('Pass')
        } else {
            setspecialChr('Fail')
        }

        if (regexallPass.test(values)) {
            setregexallPass('Pass')
        } else {
            setregexallPass('Fail')
        }
    };

    const handleChangePassC = async (e) => {
        let values = e.target.value;

        const lenghts = new RegExp("^(?=.{8,})");
        const lowerCase = new RegExp("^(?=.*[a-z])");
        const upperCase = new RegExp("^(?=.*[A-Z])");
        const numbers = new RegExp("^(?=.*[0-9])");
        const specialChr = new RegExp("^(?=.*[!@#$%^&*])");
        const regexallPass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        if (lenghts.test(values)) {
            setCLenghts('Pass')
        } else {
            setCLenghts('Fail')
        }

        if (lowerCase.test(values)) {
            setCLowerCase('Pass')
        } else {
            setCLowerCase('Fail')
        }

        if (upperCase.test(values)) {
            setCUpperCase('Pass')
        } else {
            setCUpperCase('Fail')
        }

        if (numbers.test(values)) {
            setCNumbers('Pass')
        } else {
            setCNumbers('Fail')
        }

        if (specialChr.test(values)) {
            setCspecialChr('Pass')
        } else {
            setCspecialChr('Fail')
        }

        if (regexallPass.test(values)) {
            setCregexallPass('Pass')
        } else {
            setCregexallPass('Fail')
        }
    };

    return (
        <>
            <section id='auth'>
                <ToastContainer />
                <div className='auth-block'>
                    <div className='auth-imgshap'>
                        <div className='auth-img'>
                            <img src={Authimg} alt='authimage' />
                        </div>
                    </div>
                    <div className='auth-froms'>
                        <div className='authboxs'>
                            <div className='logoimgs'>
                                <Link to={'/'}>
                                    <img src={Logo} alt='' title='' />
                                </Link>
                            </div>
                            <div className='forms_boxs'>
                                <div className='titles'>
                                    <h2>Reset / Change Password </h2>
                                    <p>(as part of set new Password)</p>
                                </div>
                                <form id='resatpass' onSubmit={handleSubmit} autoComplete="off">
                                    <div className='form-group'>
                                        <div className="control has-icon">
                                            <label className="label auth-label" htmlFor="password">new Password</label>
                                            <input type="password"
                                                id="password"
                                                name="password"
                                                className="input v-input"
                                                autoComplete='off'
                                                value={values.password}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChangePass(e)
                                                    handleChange('password')(e)
                                                }}
                                                onFocus={(e) => {
                                                    handleBlur('password')(e);
                                                    setFieldTouched('confirmpassword', false, false);
                                                }}
                                            />
                                            <label className="label form-icon" htmlFor="password">
                                                <FaLock />
                                            </label>
                                            {touched.password && errors.password && (
                                                <div className='errorboxs'>
                                                    <FaCaretUp />
                                                    <div className="errorbox">
                                                        <div className='passgrids'>
                                                            <p>8 Characters : </p>
                                                            <p className={lenghts == 'Fail' ? 'red' : 'green'}>{lenghts}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 LowerCase : </p>
                                                            <p className={lowerCase == 'Fail' ? 'red' : 'green'}>{lowerCase}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 Uppercase : </p>
                                                            <p className={upperCase == 'Fail' ? 'red' : 'green'}>{upperCase}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 Number : </p>
                                                            <p className={numbers == 'Fail' ? 'red' : 'green'}>{numbers}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 Special Character : </p>
                                                            <p className={specialChr == 'Fail' ? 'red' : 'green'}>{specialChr}</p>
                                                        </div>
                                                        <div className='passgrids bottomg'>
                                                            <p>Good Password : </p>
                                                            <p className={regexallPass == 'Fail' ? 'red' : 'green'}>{regexallPass}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {touched.password && errors.password ? (
                                                <p className='errormsg'>{errors.password}</p>
                                            ) : null}
                                            <p className='notes'>NOTE: Do not use & in passwords, must be at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</p>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <div className="control has-icon">
                                            <label className="label auth-label" htmlFor="confirmpassword">confirm Password</label>
                                            <input type="password"
                                                id="confirmpassword"
                                                name="confirmpassword"
                                                className="input v-input"
                                                autoComplete='off'
                                                value={values.confirmpassword}
                                                onBlur={handleBlur}
                                                onChange={(e) => {
                                                    handleChangePassC(e)
                                                    handleChange('confirmpassword')(e)
                                                }}
                                                onFocus={(e) => {
                                                    handleBlur('confirmpassword')(e);
                                                    setFieldTouched('password', false, false);
                                                }}
                                            />
                                            <label className="label form-icon" htmlFor="confirmpassword">
                                                <FaLock />
                                            </label>
                                            {touched.confirmpassword && errors.confirmpassword && (
                                                <div className='errorboxs'>
                                                    <FaCaretUp />
                                                    <div className="errorbox">
                                                        <div className='passgrids'>
                                                            <p>8 Characters : </p>
                                                            <p className={clenghts == 'Fail' ? 'red' : 'green'}>{clenghts}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 LowerCase : </p>
                                                            <p className={clowerCase == 'Fail' ? 'red' : 'green'}>{clowerCase}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 Uppercase : </p>
                                                            <p className={cupperCase == 'Fail' ? 'red' : 'green'}>{cupperCase}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 Number : </p>
                                                            <p className={cnumbers == 'Fail' ? 'red' : 'green'}>{cnumbers}</p>
                                                        </div>
                                                        <div className='passgrids'>
                                                            <p>1 Special Character : </p>
                                                            <p className={cspecialChr == 'Fail' ? 'red' : 'green'}>{cspecialChr}</p>
                                                        </div>
                                                        <div className='passgrids bottomg'>
                                                            <p>Good Password : </p>
                                                            <p className={cregexallPass == 'Fail' ? 'red' : 'green'}>{cregexallPass}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                            {touched.confirmpassword && errors.confirmpassword ? (
                                                <p className='errormsg'>{errors.confirmpassword}</p>
                                            ) : null}
                                            <p className='notes'>NOTE: Passwords must be at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</p>
                                        </div>
                                    </div>
                                    {isFalse ? (
                                        <div className='form-group'>
                                            <div className="control is-flex space">
                                                <p>Send New Email Request <Link className='links' to={'/forgot-password'}>Forgot Password</Link></p>
                                            </div>
                                        </div>
                                    ) : null}
                                    <div className='buttonbox'>
                                        <button type='submit' className='btn btn-green'>Submit</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : null}
            </section>
        </>
    );
}

export default ChangePassword