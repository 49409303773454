import React, { useState, useEffect } from 'react';
import Authimg from '../assets/images/login-bg.svg';

import { Link, useNavigate } from "react-router-dom";

import { useFormik } from 'formik';
import { forgotPasswordSchema } from '../schemas';

import { FaEnvelope } from "react-icons/fa";

import Loader from '../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { postForgotPassword } from '../apiservices/ApiServices';
import Logo from "../assets/images/logo-inverse.svg";

const initialValues = {
    email: '',
}

const ForgotPassword = () => {
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            navigate("/activity/dashboard");
        }
    }, [navigate])

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: forgotPasswordSchema,
        onSubmit: (vlaues, action) => {
            const isVarify = localStorage.getItem('isVarify');
            setLoading(true);
            if (isVarify === "true") {
                postForgotPassword(vlaues).then((res) => {
                    try {
                        console.log('res', res)
                        setLoading(false);
                        toast.success('A link has been sent to your registered email Id to reset password');
                        action.resetForm();
                        setSuccess(true);
                    } catch (error) {
                        setLoading(false);
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                });
            }
            else {
                setLoading(false);
                toast.error('RECAPTCHA verification failed');
                setTimeout(() => window.location.reload(true), 3000);
            }
        }
    })

    return (
        <>
            <section id='auth'>
                <ToastContainer />
                <div className='auth-block'>
                    <div className='auth-imgshap'>
                        <div className='auth-img'>
                            <img src={Authimg} alt='authimage' />
                        </div>
                    </div>
                    <div className='auth-froms'>
                        <div className='authboxs'>
                            <div className='logoimgs'>
                                <Link to={'/'}>
                                    <img src={Logo} alt='' title='' />
                                </Link>
                            </div>
                            <div className='forms_boxs'>
                                {isSuccess ? (<>
                                    <div className='titles'>
                                        <h2>Forgot Password</h2>
                                        <p>A link has been sent to your registered email Id to reset password</p>
                                        <div className='buttonbox'>
                                            <Link to={'/'} className='btn btn-green'>Ok</Link>
                                        </div>
                                    </div>
                                </>) : (
                                    <>
                                        <div className='titles'>
                                            <h2>Forgot Password</h2>
                                            <p>(as part of Forgot Password we will Send Link your email address)</p>
                                        </div>
                                        <form id='forgotpassform' onSubmit={handleSubmit} autoComplete="off">
                                            <div className='form-group'>
                                                <div className="control has-icon">
                                                    <label className="label auth-label" htmlFor="email">Email Address</label>
                                                    <input type="email"
                                                        id="email"
                                                        name="email"
                                                        className="input v-input"
                                                        autoComplete='off'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <p className='errormsg'>{errors.email}</p>
                                                    ) : null}
                                                    <label className="label form-icon" htmlFor="email">
                                                        <FaEnvelope />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className="control is-flex space">
                                                    <p>Back to <Link className='links' to={'/'}>Login</Link></p>
                                                </div>
                                            </div>
                                            <div className='buttonbox'>
                                                <button type='submit' className='btn btn-green'>Submit</button>
                                            </div>
                                        </form>
                                    </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : null}
            </section>
        </>
    );
}

export default ForgotPassword