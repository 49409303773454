import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button } from 'react-bootstrap';
import DataTable from "react-data-table-component";

import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';

import { getAllParticipantLog, getUser } from '../../apiservices/ApiServices';
import Loader from '../../components/Loader/Loader';

import { format } from 'date-fns';
import axios from 'axios';

const ParticipantHistory = (props) => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [user, setUser] = useState([]);
    const itemsPerPage = 5;

    const convertDateTimeFormat = (dateTimeString) => {
      const date = new Date(dateTimeString);
      return format(date, 'dd-MM-yyyy hh:mm a');
    };
  
    useEffect(() => 
    {
        if(!localStorage.getItem('userToken'))
        {
            navigate("/");
        }
        else 
        {
            getAllParticipantLog().then((res) => 
            {
                try {
                    console.log(res.data)
                    setData(res.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

            getUser().then((res) => 
              {
                  try {
                      setUser(res.data);
                      setLoading(false);
                  } catch (error) {
                      console.error(error);
                  } finally {
                      setLoading(false);
                  }
              });

              axios.interceptors.response.use(
                (response) => response,
                (error) => {
                  if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('userToken');
                    navigate('/'); // Redirect to login screen
                  }
                  return Promise.reject(error);
                }
            );
        }
    }, [navigate])

    const customStyles = {
        rows: {
          style: {
            minHeight: '40px', // override the row height
          },
        },
        headCells: {
          style: {
            padding: '12px 15px',
            background: '#eaecf4',
            fontFamily: 'Poppins-Bold',
            fontSize: '16px',
            color: '#000',
            borderColor: '#e3e6f0',
          },
        },
        cells: {
          style: {
            padding: '12px 15px',
            background: '#fff',
            fontFamily: 'Poppins',
            fontSize: '15px',
            color: '#606060',
            borderColor: '#e3e6f0',
          },
        },
    };
    
    const columns = [        
        {
          name: 'Shortcode',
          selector: row => row.shortcodeName,
          sortable: true,
        },
        {
          name: 'Mobile',
          selector: row => row.mobilenumber,
          sortable: true,
        },
        {
          name: 'Answer',
          selector: row => row.optionAnswer,
          sortable: true,
        },
        {
          name: 'ORZI Credit',
          selector: row => row.charges,
          sortable: true,
        },
        {
          name: 'Source',
          selector: row => row.source,
          sortable: true,
        },
        {
          name: 'Date/Time',
          selector: row => `${convertDateTimeFormat(row.createdOn)}`,
          sortable: true,
        },
    ];

    const totalPages = Math.ceil(data.length / itemsPerPage);

    // Get the items for the current page
    const currentItems = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );
  return (
    <>
        <Header UserData={user}/>
        <section id='pagecontent'>
            <div className='pagecontent-box'>
                <Sibebar UserData={user}/>
                <div className='contents'>
                    <div className='pagetitle'>
                        <h1>Participant History</h1>
                    </div>
                    <div className='cards tables mt-2'>
                        <div className='cards-hedar'>
                            <h4>Participant History</h4>
                        </div>
                        {isLoading ? <Loader /> : 
                        <div className='cards-body'>
                          <div className='resdnone-tab'>
                            <DataTable
                              columns={columns}
                              data={data}
                              customStyles={customStyles}
                              pagination
                              {...props}
                            />
                          </div>
                          <div className='tablescards'>
                            {currentItems.map((item, index) => {
                              return (
                                <div className='cardstbs' key={`listitem_${index}`}>
                                  <div className='cmpdata' style={{padding: 0}}>
                                    <p><b>Shortcode :</b> {item.shortcodeName}</p>
                                    <p><b>Mobile :</b> {item.mobilenumber}</p>
                                    <p><b>Answer :</b> {item.optionAnswer}</p>
                                    <p><b>ORZI Credit :</b> {item.charges}</p>
                                    <p><b>Source :</b> {item.source}</p>
                                    <p><b>Date/Time :</b> {`${convertDateTimeFormat(item.createdOn)}`}</p>
                                  </div>
                                </div>
                              );
                            })}
                            {data.length > 5 ? (
                            <div className="pagination-controls">
                                <Button onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} disabled={currentPage === 1}>Previous</Button>
                                <span>Page {currentPage} of {totalPages}</span>
                                <Button onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages}>Next</Button>
                            </div>
                            ) : null}
                          </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </section>
        <Footer />
    </>
  )
}
export default ParticipantHistory