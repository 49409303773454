import React, { useState, useEffect } from 'react';
import Authimg from '../assets/images/login-bg.svg';

import {Link, useNavigate } from "react-router-dom";

import { useFormik } from 'formik';
import { registerSchema } from '../schemas';

import { FaEnvelope, FaLock, FaCaretUp  } from "react-icons/fa";

import Loader from '../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { postRegiter } from '../apiservices/ApiServices';

import Logo from "../assets/images/logo-inverse.svg";

const initialValues = {
    email: '',
    mobile: '',
    password: '',
    confirmpassword: '',
}


const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');
  
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}`;
  };

const Register = () => {
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const navigate = useNavigate();

    const [lenghts, setLenghts] = useState('Fail');
    const [lowerCase, setLowerCase] = useState('Fail');
    const [upperCase, setUpperCase] = useState('Fail');
    const [numbers, setNumbers] = useState('Fail');
    const [specialChr, setspecialChr] = useState('Fail');
    const [regexallPass, setregexallPass] = useState('Fail');

    const [clenghts, setCLenghts] = useState('Fail');
    const [clowerCase, setCLowerCase] = useState('Fail');
    const [cupperCase, setCUpperCase] = useState('Fail');
    const [cnumbers, setCNumbers] = useState('Fail');
    const [cspecialChr, setCspecialChr] = useState('Fail');
    const [cregexallPass, setCregexallPass] = useState('Fail');

    const [startWith, setWtartWith] = useState('Fail');
    const [digit, setDigit] = useState('Fail');
    const [mobileFormat, setMobileFormat] = useState('Fail');


    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };
    
    useEffect (() => {
        if(localStorage.getItem('userToken')) 
        {
            navigate("/activity/dashboard");  
        }
    },[navigate])

    const handleChangeMobile = async (e) => {
        let values=e.target.value;
        console.log(values)

        const startWith = new RegExp("^447");
        const digit = new RegExp("^[0-9]{12}$");
        const mobileFormat = new RegExp("^447\\d{9}$");

        if (startWith.test(values)) {
            setWtartWith('Pass')
        } else {
            setWtartWith('Fail')
        }

        if (digit.test(values)) {
            setDigit('Pass')
        } else {
            setDigit('Fail')
        }

        if (mobileFormat.test(values)) {
            setMobileFormat('Pass')
        } else {
            setMobileFormat('Fail')
        }


    };

    const handleChangePass = async (e) => {
        let values=e.target.value;

        const lenghts = new RegExp("^(?=.{8,})");
        const lowerCase = new RegExp("^(?=.*[a-z])");
        const upperCase = new RegExp("^(?=.*[A-Z])");
        const numbers = new RegExp("^(?=.*[0-9])");
        const specialChr = new RegExp("^(?=.*[!@#$%^&*])");
        const regexallPass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        if (lenghts.test(values)) {
            setLenghts('Pass')
        } else {
            setLenghts('Fail')
        }

        if (lowerCase.test(values)) {
            setLowerCase('Pass')
        } else {
            setLowerCase('Fail')
        }

        if (upperCase.test(values)) {
            setUpperCase('Pass')
        } else {
            setUpperCase('Fail')
        }

        if (numbers.test(values)) {
            setNumbers('Pass')
        } else {
            setNumbers('Fail')
        }

        if (specialChr.test(values)) {
            setspecialChr('Pass')
        } else {
            setspecialChr('Fail')
        }

        if (regexallPass.test(values)) {
            setregexallPass('Pass')
        } else {
            setregexallPass('Fail')
        }
    };

    const handleChangePassC = async (e) => {
        let values=e.target.value;

        const lenghts = new RegExp("^(?=.{8,})");
        const lowerCase = new RegExp("^(?=.*[a-z])");
        const upperCase = new RegExp("^(?=.*[A-Z])");
        const numbers = new RegExp("^(?=.*[0-9])");
        const specialChr = new RegExp("^(?=.*[!@#$%^&*])");
        const regexallPass = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})");

        if (lenghts.test(values)) {
            setCLenghts('Pass')
        } else {
            setCLenghts('Fail')
        }

        if (lowerCase.test(values)) {
            setCLowerCase('Pass')
        } else {
            setCLowerCase('Fail')
        }

        if (upperCase.test(values)) {
            setCUpperCase('Pass')
        } else {
            setCUpperCase('Fail')
        }

        if (numbers.test(values)) {
            setCNumbers('Pass')
        } else {
            setCNumbers('Fail')
        }

        if (specialChr.test(values)) {
            setCspecialChr('Pass')
        } else {
            setCspecialChr('Fail')
        }

        if (regexallPass.test(values)) {
            setCregexallPass('Pass')
        } else {
            setCregexallPass('Fail')
        }
    };

    const { values, errors, touched, handleBlur, handleChange, setFieldTouched, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: registerSchema,
        onSubmit: (values, action) => {
            let postdata = {
                "publicUserId": "0",
                "emailId": values.email,
                "mobilenumber": values.mobile,
                "password": values.password,
                "availableCredit": 0,
                "activationcode": "",
                "is2FactorAuthentication": "false",
                "authenticationId": 0,
                "isVerify": "false",
                "isEmailVerify": "false",
                "isMobileVerify": "false",
                "isActive": "false",
                "isSuspend": "false",
                "createdBy": "",
                "createdOn": getCurrentDateTime(),
                "updatedBy": "",
                "updatedOn": getCurrentDateTime(),
                "isTnCchecked": "true",
                "activationCodeGenOn": "",
                "identityUserId": ""
            }

            const isVarify = localStorage.getItem('isVarify');
            setLoading(true);
           if (isVarify === "true")
            {  
                postRegiter(postdata).then((res) => {
                    try {
                        console.log('res', res)
                        setLoading(false);
                        if(res.data.succeeded == false)
                        {
                            toast.error(res.data.message);   
                        }
                        else
                        {
                            toast.success('Register Successful');                  
                            action.resetForm();
                            setSuccess(true);
                        }
                    } catch (error) {
                        setLoading(false);
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                });
            }
            else {
                setLoading(false);
                toast.error('RECAPTCHA verification failed');
                setTimeout(() => window.location.reload(true), 3000);
            }
        }
    })

    return (
        <>
            <section id='auth'>
                <ToastContainer />
                <div className='auth-block'>
                    <div className='auth-imgshap'>
                        <div className='auth-img'>
                            <img src={Authimg} alt='authimage'/>
                        </div>
                    </div>
                    <div className='auth-froms'>
                        <div className='authboxs'>
                            <div className='logoimgs'>
                                <Link to={'/'}>
                                    <img src={Logo} alt='' title='' />
                                </Link>
                            </div>
                            <div className='forms_boxs'>
                            {isSuccess ? ( <>
                                <div className='titles text-center'>
                                    <h4>Thank You For Registration</h4>
                                    <p>Please check your email (inbox & junk folder) to activate your account.</p>
                                    <div className='buttonbox'>
                                        <Link to={'/'} className='btn btn-green'>Ok</Link>
                                    </div>
                                </div>
                            </> ) : (
                                <>
                                    <div className='titles'>
                                        <h2>New User Registration</h2>
                                        <p>(as part of registration we will verify your mobile number & email address)</p>
                                    </div>
                                    <form id='loginform' onSubmit={handleSubmit} autoComplete="off">                               
                                        <div className='form-group'>
                                            <div className="control has-icon">
                                                <label className="label auth-label" htmlFor="email">Email Address</label>
                                                <input type="email"
                                                    id="email"
                                                    name="email"
                                                    className="input v-input" 
                                                    autoComplete='off'
                                                    value={values.email}
                                                    onBlur={handleBlur}
                                                    onChange={handleChange('email')}
                                                    onFocus={(e) => {
                                                        handleBlur('email')(e);
                                                        setFieldTouched('confirmpassword', false, false);
                                                        setFieldTouched('mobile', false, false);
                                                        setFieldTouched('password', false, false);
                                                    }}
                                                />
                                                {errors.email && touched.email ? (
                                                    <p className='errormsg'>{errors.email}</p>
                                                ) : null}
                                                <label className="label form-icon" htmlFor="email">
                                                    <FaEnvelope />
                                                </label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="control has-icon">
                                                <label className="label auth-label" htmlFor="mobile">Mobile Number</label>
                                                <input type="tel"
                                                    name='mobile'
                                                    id='mobile'
                                                    className="input v-input" 
                                                    maxLength={12}
                                                    autoComplete='off'
                                                    value={values.mobile}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChangeMobile(e)
                                                        handleChange('mobile')(e)
                                                    }}
                                                    onFocus={(e) => {
                                                        handleBlur('mobile')(e);
                                                        setFieldTouched('email', false, false);
                                                        setFieldTouched('password', false, false);
                                                        setFieldTouched('confirmpassword', false, false);
                                                    }}
                                                />
                                                <label className="label form-icon" htmlFor="mobile">
                                                    <FaEnvelope />
                                                </label>

                                                {touched.mobile && errors.mobile && (
                                                    <div className='errorboxs'>
                                                        <FaCaretUp />
                                                        <div className="errorbox">
                                                            <div className='passgrids'>
                                                                <p>Start with 447 : </p>
                                                                <p className={startWith == 'Fail' ? 'red' : 'green'}>{startWith}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>12 digit : </p>
                                                                <p className={digit == 'Fail' ? 'red' : 'green'}>{digit}</p>
                                                            </div>
                                                            <div className='passgrids bottomg'>
                                                                <p>Mobile Format : </p>
                                                                <p className={mobileFormat == 'Fail' ? 'red' : 'green'}>{mobileFormat}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {touched.mobile && errors.mobile ? (
                                                    <p className='errormsg'>{errors.mobile}</p>
                                                ) : null}
                                                <p className='notes'>NOTE: Mobile number format should be 447797763565 and contain 12 digits. U.K, IOM, & Channel Islands mobiles only.</p>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="control has-icon">
                                                <label className="label auth-label" htmlFor="password">Password</label>
                                                <input type="password"
                                                    id="password"
                                                    name="password"
                                                    className="input v-input" 
                                                    autoComplete='off'
                                                    value={values.password}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChangePass(e)
                                                        handleChange('password')(e)
                                                    }}
                                                    onFocus={(e) => {
                                                        handleBlur('password')(e);
                                                        setFieldTouched('email', false, false);
                                                        setFieldTouched('mobile', false, false);
                                                        setFieldTouched('confirmpassword', false, false);
                                                    }}
                                                />
                                                <label className="label form-icon" htmlFor="password">
                                                    <FaLock />
                                                </label>
                                                {touched.password && errors.password && (
                                                    <div className='errorboxs'>
                                                        <FaCaretUp />
                                                        <div className="errorbox">
                                                            <div className='passgrids'>
                                                                <p>8 Characters : </p>
                                                                <p className={lenghts == 'Fail' ? 'red' : 'green'}>{lenghts}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 LowerCase : </p>
                                                                <p className={lowerCase == 'Fail' ? 'red' : 'green'}>{lowerCase}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 Uppercase : </p>
                                                                <p className={upperCase == 'Fail' ? 'red' : 'green'}>{upperCase}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 Number : </p>
                                                                <p className={numbers == 'Fail' ? 'red' : 'green'}>{numbers}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 Special Character : </p>
                                                                <p className={specialChr == 'Fail' ? 'red' : 'green'}>{specialChr}</p>
                                                            </div>
                                                            <div className='passgrids bottomg'>
                                                                <p>Good Password : </p>
                                                                <p className={regexallPass == 'Fail' ? 'red' : 'green'}>{regexallPass}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {touched.password && errors.password ? (
                                                    <p className='errormsg'>{errors.password}</p>
                                                ) : null}
                                                <p className='notes'>NOTE: Do not use & in passwords, must be at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</p>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="control has-icon">
                                                <label className="label auth-label" htmlFor="confirmpassword">Confirm Password</label>
                                                <input type="password"
                                                    id="confirmpassword"
                                                    name="confirmpassword"
                                                    className="input v-input" 
                                                    autoComplete='off'
                                                    value={values.confirmpassword}
                                                    onBlur={handleBlur}
                                                    onChange={(e) => {
                                                        handleChangePassC(e)
                                                        handleChange('confirmpassword')(e)
                                                    }}
                                                    onFocus={(e) => {
                                                        handleBlur('confirmpassword')(e);
                                                        setFieldTouched('email', false, false);
                                                        setFieldTouched('mobile', false, false);
                                                        setFieldTouched('password', false, false);
                                                    }}
                                                />
                                                <label className="label form-icon" htmlFor="confirmpassword">
                                                    <FaLock />
                                                </label>
                                                {touched.confirmpassword && errors.confirmpassword && (
                                                    <div className='errorboxs'>
                                                        <FaCaretUp />
                                                        <div className="errorbox">
                                                            <div className='passgrids'>
                                                                <p>8 Characters : </p>
                                                                <p className={clenghts == 'Fail' ? 'red' : 'green'}>{clenghts}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 LowerCase : </p>
                                                                <p className={clowerCase == 'Fail' ? 'red' : 'green'}>{clowerCase}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 Uppercase : </p>
                                                                <p className={cupperCase == 'Fail' ? 'red' : 'green'}>{cupperCase}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 Number : </p>
                                                                <p className={cnumbers == 'Fail' ? 'red' : 'green'}>{cnumbers}</p>
                                                            </div>
                                                            <div className='passgrids'>
                                                                <p>1 Special Character : </p>
                                                                <p className={cspecialChr == 'Fail' ? 'red' : 'green'}>{cspecialChr}</p>
                                                            </div>
                                                            <div className='passgrids bottomg'>
                                                                <p>Good Password : </p>
                                                                <p className={cregexallPass == 'Fail' ? 'red' : 'green'}>{cregexallPass}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                {touched.confirmpassword && errors.confirmpassword ? (
                                                    <p className='errormsg'>{errors.confirmpassword}</p>
                                                ) : null}
                                                <p className='notes'>NOTE: Passwords must be at least 8 characters, 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character.</p>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="control is-flex">
                                                <label className="remember-toggle" htmlFor="v-field-3">
                                                    <input id="v-field-3" name="v-field-3" type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                                    <span className="toggler">
                                                        <span className="active">
                                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="feather:check" className="iconify iconify--feather">
                                                                <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 6L9 17l-5-5"></path>
                                                            </svg>
                                                        </span>
                                                        <span className="inactive">
                                                            <svg xmlns="http://www.w3.org/2000/svg" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="feather:circle" className="iconify iconify--feather">
                                                                <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></circle>
                                                            </svg>
                                                        </span>
                                                    </span>
                                                </label>
                                                <label className="remember-me" htmlFor="v-field-3">Please tick to accept our <Link className='links' to={'/TermsConditions'}>Terms and Conditions</Link> & <Link className='links' to={'/PrivacyPolicy'}>Privacy Policy</Link></label>
                                            </div>
                                        </div>
                                        <div className='form-group'>
                                            <div className="control is-flex space">
                                                <p>Already have an account ? <Link className='links' to={'/'}>Login</Link></p>
                                                <Link className='links' to={'/forgot-password'}>Forgot Password?</Link>
                                            </div>
                                        </div>
                                        <div className='buttonbox'>
                                            {!isCheckboxChecked ? 
                                                <button type='button' disabled className='btn btn-green'>Register</button> :
                                                <button type='submit' disabled="" className='btn btn-green'>Register</button>
                                            }
                                        </div>
                                    </form>
                                </>)
                            }    
                            </div>                        
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : null}
            </section>
        </>
    );
}

export default Register