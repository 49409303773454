import React from 'react';
import Logo from "../../assets/images/orzilogo.svg";
import { Link } from "react-router-dom";
import Footer from '../../includes/Footer';


import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PrivacyPolicy = () => {
  return (
    <>
      <header id='header'>
        <div className='header-block'>
          <div className='logo-box' id='heds'>
            <Link to={'/'}>
              <img src={Logo} alt='' title='' />
            </Link>
          </div>
          <div className='rightnav'>
            <ul>
              <li><h6>ORZI.App</h6></li>
            </ul>
          </div>
        </div>
      </header>

      <Worker workerUrl="https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js">
        <Viewer fileUrl="Privacy_Policy.pdf" />
      </Worker>

      <Footer/>
    </>
  )
}
export default PrivacyPolicy;