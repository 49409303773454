import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import Footer from '../../includes/Footer';
import Header from '../../includes/Header';
import Sibebar from '../../includes/Sibebar';

import { useFormik } from 'formik';
import { shortcodeentrySchema } from '../../schemas';

import { FaCheckCircle, FaPenSquare } from "react-icons/fa";

import Loader from '../../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { format } from 'date-fns';

import { postAddParticipantLog, getShortcodeList, getUser, } from '../../apiservices/ApiServices';
import axios from 'axios';


const initialValues = {
    shortcode: '',
    answer: '',
}
const ShortcodeEntry = () => {
    const [isLoading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [user, setUser] = useState([]);
    const [instruction, setInstruction] = useState('');
    const [entryFee, setEntryFee] = useState('');
    const [currentDateTime, setCurrentDateTime] = useState(new Date());
    const navigate = useNavigate();
    const { id } = useParams();


    const convertDateFormat = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'MMMM yy');
    };


    useEffect(() => {
        if(!localStorage.getItem('userToken'))
        {
            navigate("/");
        }
        else {
            getShortcodeList().then((res) => {
                try {
                    console.log(res.data)
                    setData(res.data);
                    setLoading(false);

                    const dataId = parseInt(id);
                    const index = res.data.findIndex(data => data.shortcodeName == dataId);
                    if (index !== -1) {
                        setFieldValue('shortcode', index);
                        setInstruction(res.data[index].campaignInstruction)
                        setEntryFee(res.data[index].entryFee)
                    }
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

            getUser().then((res) => {
                try {
                    setUser(res.data);
                } catch (error) {
                    console.error(error);
                } finally {
                    setLoading(false);
                }
            });

            axios.interceptors.response.use(
                (response) => response,
                (error) => {
                  if (error.response && error.response.status === 401) {
                    localStorage.removeItem('authToken');
                    localStorage.removeItem('userToken');
                    navigate('/'); // Redirect to login screen
                  }
                  return Promise.reject(error);
                }
            );

        }
    }, [navigate, id, setData])

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(timer); // Clean up the timer on component unmount
    }, []);

    const { values, errors, touched, handleBlur, setFieldValue, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: shortcodeentrySchema,
        onSubmit: (vlaues, action) => {
            let postdata = {
                "participantLogId": 0,
                "shortcodeName": data[vlaues.shortcode].shortcodeName,
                "mobilenumber": user.mobilenumber,
                "optionAnswer": vlaues.answer,
                "charges": data[vlaues.shortcode].entryFee,
                "source": "ORZI.App",
                "createdBy": 0,
                "createdOn": currentDateTime,
                "campaignId": data[vlaues.shortcode].campaignId,
                "operatorId": 1,
                "attemptStatus": "SUCCESS",
                "operatorName": ""
            }
            const isVarify = localStorage.getItem('isVarify');
            setLoading(true);
            if (isVarify === "true")
            {
                postAddParticipantLog(postdata).then((res) => {
                    try {
                        setLoading(false);

                        getUser().then((res) => {
                            try {
                                setUser(res.data);
                            } catch (error) {
                                console.error(error);
                            } finally {
                                setLoading(false);
                            }
                        });
                        toast.success(res.data);
                        action.resetForm();

                    } catch (error) {
                        setLoading(false);
                        console.error(error);
                    } finally {
                        setLoading(false);
                    }
                });
            }
            else {
                setLoading(false);
                toast.error('RECAPTCHA verification failed');
                setTimeout(() => window.location.reload(true), 3000);
            }
        }
    })
    return (
        <>
            <Header UserData={user}/>
            <section id='pagecontent'>
                <ToastContainer />
                <div className='pagecontent-box'>
                    <Sibebar UserData={user}/>
                    <div className='contents'>
                        <div className='pagetitle'>
                            <h1>Shortcode Entry </h1>
                        </div>
                        <div className='cards tables mt-2'>
                            <div className='cards-hedar'>
                                <h4>Shortcode Entry</h4>
                            </div>
                            {isLoading ? (
                                <Loader />
                            ) :
                                <div className='cards-body'>
                                    <div className='cardinfo forms'>
                                        <div className='usersinfos'>
                                            <p><b>User Name :</b> {user.emailId}</p>
                                            <p><b>User Mobile :</b> {user.mobilenumber} </p>
                                            <p><b>ORZI Credit :</b> {user.availableCredit}</p>
                                        </div>

                                        <form id='addparticipant' onSubmit={handleSubmit} autoComplete="off">
                                            <div className='form-group'>
                                                <div className="control has-icon">
                                                    <label className="label auth-label" htmlFor="shortcode">Select Shortcode / Campaign:</label>
                                                    <select
                                                        id='shortcode'
                                                        className='select input v-input'
                                                        value={values.shortcode}
                                                        onChange={(e) => {
                                                            const selectedValue = e.target.value;
                                                            if (selectedValue === 'selects') {
                                                                setFieldValue('shortcode', '');
                                                            }
                                                            else {
                                                                setFieldValue('shortcode', selectedValue);
                                                                setInstruction(data[selectedValue].campaignInstruction);
                                                                setEntryFee(data[selectedValue].entryFee)
                                                            }

                                                        }}
                                                        onBlur={handleBlur}
                                                    >
                                                        <option value={'selects'}>Select Shortcode</option>
                                                        {data.map((item, index) => {
                                                            return (
                                                                <option key={item.shortcodeId} value={index}>{`${item.shortCampaignName}`}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    {/* <option key={item.shortcodeId} value={index}>{`${item.shortCampaignName} ${convertDateFormat(item.enddate)}`}</option> */}
                                                    {errors.shortcode && touched.shortcode ? (
                                                        <p className='errormsg'>{errors.shortcode}</p>
                                                    ) : null}
                                                    <label className="label form-icon" htmlFor="shortcode">
                                                        <FaCheckCircle />
                                                    </label>
                                                </div>
                                                <div className='notes'>
                                                    <h6>{instruction ? ` Instruction: ${instruction}` : ' Instruction:'}</h6>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className="control has-icon">
                                                    <label className="label auth-label" htmlFor="answer">Answer</label>
                                                    <textarea
                                                        id="answer"
                                                        name="answer"
                                                        className="input v-input"
                                                        autoComplete='off'
                                                        value={values.answer}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.answer && touched.answer ? (
                                                        <p className='errormsg'>{errors.answer}</p>
                                                    ) : null}
                                                    <label className="label form-icon" htmlFor="answer">
                                                        <FaPenSquare />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='credits'><h6>Credits required to participate: {entryFee ? `${entryFee} Credits` : null} </h6></div>
                                            <div className='buttonbox'>
                                                <button type='submit' className='btn btn-green'>Submit</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ShortcodeEntry