import React from 'react';
import { FaMapMarkerAlt } from "react-icons/fa";

const Footer = () => {
  return (
    <footer>
        <div className='footer-block'>
            <div className='address'>
                <div className='icons'><FaMapMarkerAlt /></div>
                <p>Floor 1 Liberation Station Esplanade, St Helier, JE2 3AS <a href="mailto:privacy@orzi.co.uk">privacy@orzi.co.uk</a></p>
            </div>
        </div>
    </footer>
  )
}

export default Footer