import React, { useState, useEffect } from 'react';
import Authimg from '../assets/images/login-bg.svg';

import { Link, useNavigate } from "react-router-dom";

import { useFormik } from 'formik';
import { loginSchema } from '../schemas';

import { FaEnvelope, FaLock } from "react-icons/fa";

import Loader from '../components/Loader/Loader';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { getuserotp, getVerify2FAOTP, postSubmitUserLogin } from '../apiservices/ApiServices';

import OtpInput from 'react-otp-input';

import Logo from "../assets/images/logo-inverse.svg";

const initialValues = {
    email: '',
    password: '',
}
const Login = () => {
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [otp, setOtp] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const [mibieVerify, setMibieVerify] = useState(false);

    const navigate = useNavigate();

    const handleChanges = (otp) => setOtp(otp);

    const handleCheckboxChange = () => {
        setIsCheckboxChecked(!isCheckboxChecked);
    };

    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            navigate("/activity/dashboard");
        }
    }, [navigate])

    const Logins = async (vlaues, action) => {
        const isVarify = localStorage.getItem('isVarify');
        if (isVarify === "true") {
            try {
                const res = await fetch(`${localStorage.getItem('apiUrl')}/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Basic Auth',
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': true,
                    },
                    body: JSON.stringify(vlaues)
                });
                const result = await res.json();
                if (result.accessToken) {
                    //action.resetForm();
                    localStorage.setItem('authToken', result.accessToken);
                    const tokens = 'Bearer ' + result.accessToken;
                    try {
                        const users = await fetch(`${localStorage.getItem('apiUrl')}/User/GetUser`, {
                            method: 'GET',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': tokens,
                                'Access-Control-Allow-Origin': '*',
                                'Access-Control-Allow-Credentials': true,
                            },
                        });
                        const Userdata = await users.json();

                        if (!Userdata.isMobileVerify && !Userdata.isSuspend) {
                            const sendotp = await fetch(`${localStorage.getItem('apiUrl')}/User/SendVerifyOTPMobile`, {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': tokens,
                                    'Access-Control-Allow-Origin': '*',
                                    'Access-Control-Allow-Credentials': true,
                                },
                            });
                            const otp = await sendotp.json();
                            if (otp.succeeded) {
                                toast.success(otp.message);
                                setMibieVerify(true);
                                setSuccess(true);
                            } else {
                                toast.error(otp.message || 'Failed to send OTP');
                                setLoading(false);
                            }
                        }
                        else if (Userdata.isActive && Userdata.isVerify && !Userdata.isSuspend) {
                            getuserotp().then((otpres) => {
                                try {
                                    console.log(otpres.data)
                                    toast.success(otpres.data.message);
                                    if (otpres.data.message === '2FA Inactive') {

                                        let postdata =
                                        {
                                            "userId": "0",
                                            "logIp": 'NA',
                                            "sourceType": "app",
                                            "isTnCChecked": true
                                        }
                                        postSubmitUserLogin(postdata).then((poslres) => {
                                            try {
                                                setTimeout(() => navigate("/activity/dashboard"), 3000);
                                                toast.success('Login Successful');
                                                localStorage.setItem('userToken', result.accessToken);
                                                localStorage.removeItem('authToken');
                                                setLoading(false)
                                            } catch (error) {
                                                setLoading(false);
                                                console.error(error);
                                            } finally {

                                            }
                                        });

                                        // });
                                    }
                                    else {
                                        setSuccess(true)
                                        setLoading(false);
                                    }
                                } catch (error) {
                                    console.error(error);
                                } finally {
                                    setLoading(false);
                                }
                            });
                        } else if (Userdata.isSuspend) {
                            toast.error('Your account is suspended');
                            setLoading(false);
                            // Your account is suspended
                        } else {
                            toast.error('Your account is not verify');
                            setLoading(false);
                        }
                        setLoading(false);
                    } catch (error) {
                        toast.error(error.message);
                        setLoading(false);
                    }
                }
                else {
                    setLoading(false);
                    toast.error('Either username or password is incorrect!');
                }
                setLoading(false);
            } catch (error) {
                toast.error(error.message);
                setLoading(false);
            }
        }
        else {
            setLoading(false);
            toast.error('RECAPTCHA verification failed');
            setTimeout(() => window.location.reload(true), 3000);
        }
    };

    const { values, errors, touched, handleBlur, handleChange, handleSubmit } = useFormik({
        initialValues: initialValues,
        validationSchema: loginSchema,
        onSubmit: (vlaues, action) => {
            setLoading(true);
            Logins(vlaues, action);

        }
    })

    async function Resendotp() {
        setLoading(true)
        try {
            const response = await fetch(`${localStorage.getItem('apiUrl')}/User/SendVerifyOTPMobile`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                    'Access-Control-Allow-Origin': '*',
                    'Access-Control-Allow-Credentials': true,
                },
            });

            const otp = await response.json();
            if (otp.succeeded) {
                toast.success(otp.message);
                setLoading(false);
                setOtp("");
                setMibieVerify(true);
                setSuccess(true);
            } else {
                toast.error(otp.message || 'Failed to send OTP');
                setLoading(false);
            }
        } catch (error) {
            console.error('Error sending OTP:', error);
            toast.error('Something went wrong, please try again.');
            setLoading(false);
        }
    }

    async function SubmiMobileOTP() {
        const isVarify = localStorage.getItem('isVarify');
        setLoading(true);
       if (isVarify === "true") {            
            try {
                const submitOtp = await fetch(`${localStorage.getItem('apiUrl')}/User/VerifyMobile?otp=${otp}`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                        'Access-Control-Allow-Origin': '*',
                        'Access-Control-Allow-Credentials': true,
                    },
                });

                const otpsubmit = await submitOtp.json();
                if (otpsubmit.succeeded) {
                    toast.success(otpsubmit.message);
                    let postdata =
                    {
                        "userId": "0",
                        "logIp": 'NA',
                        "sourceType": "app",
                        "isTnCChecked": true
                    }
                    postSubmitUserLogin(postdata).then((res) => {
                        try {
                            toast.success('Login Successful');
                            setLoading(false);
                            setOtp("");
                            setMibieVerify(false);
                            setSuccess(false);

                            let token = localStorage.getItem('authToken')
                            localStorage.setItem('userToken', token);
                            setTimeout(() => navigate("/activity/dashboard"), 3000);
                            setLoading(false)
                        } catch (error) {
                            toast.error('Something went wrong, please try again.');
                            setLoading(false);
                        } finally {

                        }
                    });
                } else {
                    toast.error(otpsubmit.message || 'Failed to Varification OTP');
                    setLoading(false);
                }
            } catch (error) {
                console.error('Error Varification OTP:', error);
                toast.error('Something went wrong, please try again.');
                setLoading(false);
            }
        }
        else {
            setLoading(false);
            toast.error('RECAPTCHA verification failed');
            setTimeout(() => window.location.reload(true), 3000);
        }
    }

    function Submitotp() {
        const isVarify = localStorage.getItem('isVarify');
        setLoading(true);
       if (isVarify === "true") {            
            getVerify2FAOTP(otp).then((res) => {
                try {
                    console.log(res.data)
                    if (res.data.message === 'Verification Failed') {
                        toast.error(res.data.message);
                        setLoading(false);
                    }
                    else {

                        let postdata =
                        {
                            "userId": "0",
                            "logIp": 'NA',
                            "sourceType": "app",
                            "isTnCChecked": true
                        }
                        postSubmitUserLogin(postdata).then((res) => {
                            try {
                                toast.success('Login Successful');
                                let token = localStorage.getItem('authToken')
                                localStorage.setItem('userToken', token);
                                setTimeout(() => navigate("/activity/dashboard"), 3000);
                                setLoading(false)
                            } catch (error) {
                                setLoading(false);
                                console.error(error);
                            } finally {

                            }
                        });
                        // });
                    }
                } catch (error) {
                    console.error(error);
                    setLoading(false)
                } finally {
                    setLoading(false)
                }
            });
        }
        else {
            setLoading(false);
            toast.error('RECAPTCHA verification failed');
            setTimeout(() => window.location.reload(true), 3000);
        }
    }
    return (
        <>
            <section id='auth'>
                <ToastContainer />
                <div className='auth-block'>
                    <div className='auth-imgshap'>
                        <div className='auth-img'>
                            <img src={Authimg} alt='authimage' />
                        </div>
                    </div>
                    <div className='auth-froms'>
                        <div className='authboxs'>
                            <div className='logoimgs'>
                                <Link to={'/'}>
                                    <img src={Logo} alt='' title='' />
                                </Link>
                            </div>
                            <div className='forms_boxs'>
                                {isSuccess ? (
                                    <>
                                        <div className='titles'>
                                            <h2>OTP Verification</h2>
                                            {mibieVerify ? <p>Enter the OTP sent to Register Mobile</p> : <p>Enter the OTP sent to Register Mobile/Email</p>}
                                        </div>
                                        <div id='otpform'>
                                            <div className='form-group'>
                                                <OtpInput
                                                    value={otp}
                                                    onChange={handleChanges}
                                                    numInputs={`${localStorage.getItem('otpLehght')}`}
                                                    isInputNum={false}
                                                    inputType='text'
                                                    shouldAutoFocus={true}
                                                    renderInput={(props) => <input {...props} />}
                                                />
                                            </div>
                                            {otp.length >= 1 && otp.length <= 5 ? (
                                                <p className='errormsg'>Please Enter OTP</p>
                                            ) : null}

                                            {mibieVerify ? (
                                                <div className='buttonbox'>
                                                    {otp.length <= 3 ? <button type='button' disabled className='btn btn-green'>Verify & Login</button> :
                                                        <button type='submit' disabled="" onClick={SubmiMobileOTP} className='btn btn-green'>Verify & Login</button>
                                                    }
                                                </div>
                                            ) : (
                                                <div className='buttonbox'>
                                                    {otp.length <= 3 ? <button type='button' disabled className='btn btn-green'>Verify & Login</button> :
                                                        <button type='submit' disabled="" onClick={Submitotp} className='btn btn-green'>Verify & Login</button>
                                                    }
                                                </div>
                                            )}
                                            {mibieVerify ? (
                                                <p style={{ marginTop: 15 }}><button type='button' className='buttons links' onClick={Resendotp}> Resend OTP</button></p>) : (
                                                <p style={{ marginTop: 15 }}>Back to <button type='button' className='buttons links' onClick={() => setSuccess(false)}> Resend OTP</button></p>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className='titles'>
                                            <h2>Sign In</h2>
                                            <p>Welcome back to your account.</p>
                                        </div>
                                        <form id='loginform' onSubmit={handleSubmit} autoComplete="off">
                                            <div className='form-group'>
                                                <div className="control has-icon">
                                                    <label className="label auth-label" htmlFor="v-field-1">Email Address</label>
                                                    <input type="email"
                                                        id="email"
                                                        name="email"
                                                        className="input v-input"
                                                        autoComplete='off'
                                                        value={values.email}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.email && touched.email ? (
                                                        <p className='errormsg'>{errors.email}</p>
                                                    ) : null}
                                                    <label className="label form-icon" htmlFor="v-field-1">
                                                        <FaEnvelope />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className="control has-icon">
                                                    <label className="label auth-label" htmlFor="v-field-2">Password</label>
                                                    <input type="password"
                                                        id="password"
                                                        name="password"
                                                        className="input v-input"
                                                        autoComplete='off'
                                                        value={values.password}
                                                        onChange={handleChange}
                                                        onBlur={handleBlur}
                                                    />
                                                    {errors.password && touched.password ? (
                                                        <p className='errormsg'>{errors.password}</p>
                                                    ) : null}
                                                    <label className="label form-icon" htmlFor="v-field-1">
                                                        <FaLock />
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className="control is-flex">
                                                    <label className="remember-toggle" htmlFor="v-field-3">
                                                        <input id="v-field-3" name="v-field-3" type="checkbox" checked={isCheckboxChecked} onChange={handleCheckboxChange} />
                                                        <span className="toggler">
                                                            <span className="active">
                                                                <svg xmlns="http://www.w3.org/2000/svg" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="feather:check" className="iconify iconify--feather">
                                                                    <path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M20 6L9 17l-5-5"></path>
                                                                </svg>
                                                            </span>
                                                            <span className="inactive">
                                                                <svg xmlns="http://www.w3.org/2000/svg" role="img" width="1em" height="1em" viewBox="0 0 24 24" data-icon="feather:circle" className="iconify iconify--feather">
                                                                    <circle cx="12" cy="12" r="10" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"></circle>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                    </label>
                                                    <label className="remember-me" htmlFor="v-field-3">Please tick to accept our <Link className='links' to={'/TermsConditions'}>Terms and Conditions</Link> & <Link className='links' to={'/PrivacyPolicy'}>Privacy Policy</Link></label>
                                                </div>
                                            </div>
                                            <div className='form-group'>
                                                <div className="control is-flex space">
                                                    <p>Don't have an account ? <Link className='links' to={'/register'}>Register</Link></p>
                                                    <Link className='links' to={'/forgot-password'}>Forgot Password?</Link>
                                                </div>
                                            </div>
                                            <div className='buttonbox'>
                                                {!isCheckboxChecked ?
                                                    <button type='button' disabled className='btn btn-green'>Verify & Login</button> :
                                                    <button type='submit' disabled="" className='btn btn-green'>Verify & Login</button>
                                                }
                                            </div>
                                        </form>
                                    </>)
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {isLoading ? (
                    <Loader />
                ) : null}
            </section>
        </>
    );
}

export default Login